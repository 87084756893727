import { Button, Form, Input } from 'antd';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import RightImg from '../../assets/images/login-three.png';
import Logo from '../../assets/images/logo.png';
import { auth } from '../../configs/firebase';
import { useFirebaseContext } from '../../context/firebase-user-context';
import { showErrorMessage } from '../../utils/utils';

type FormInput = {
  email: string;
  password: string;
};

function Login() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const onFinish = async (input: FormInput) => {
    try {
      setLoading(true);
      await signInWithEmailAndPassword(auth, input.email, input.password);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const [form] = Form.useForm<FormInput>();

  const { user } = useFirebaseContext();

  useEffect(() => {
    if (user) {
      navigate('/dashboard', {
        replace: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div className="login-page posiition-relativ">
      <div className="login-left grid grid-cols-10  ">
        <div className="relative col-span-10  text-center md:col-span-3">
          <div className="login-left flex flex-col justify-center  p-5 md:min-h-screen ">
            <div className="form-field ">
              <img className="logo" src={Logo} alt="" />
              <Form form={form} layout="vertical" className="text-center" onFinish={onFinish}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    { required: true, message: 'Please input a valid email!', type: 'email' },
                  ]}
                >
                  <Input className="rounded-full" />
                </Form.Item>

                <Form.Item
                  label="Password"
                  name="password"
                  rules={[{ required: true, message: 'Please input your password!' }]}
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item>
                  <Button loading={loading} shape="round" type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
        <div className="col-span-10   md:col-span-7  ">
          <div className=" flex flex-col justify-end   md:min-h-screen">
            <img src={RightImg} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Login;
